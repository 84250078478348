    /* You can add global styles to this file, and also import other style files */
    
    * {
        html,
        body {
            margin: 0
        }
        font-family: Roboto,
        Helvetica,
        sans-serif;
    }